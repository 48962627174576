
.d-none{
    display: none !important;
}
.plan{
    position: relative;
}

.plan::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 10em;
    background-color: #FFF;
    z-index: 1;
}
.plans {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: #FFF;
    width: 100%;
    gap: 60px;
    padding: 50px;
}

.show {
    display: none;
}

.page-title {
    text-align: center;
    font-weight: 800;
    padding: 120px;
    margin: 0;
}


.options {
    display: flex;
    justify-content: center;
    align-items: center;
}

.options .option {
    background: #D7D7D7;
    padding: 15px;
    font-size: 12px;
    text-align: center;
    color: #A9A9A9;
    align-self: flex-end;
    cursor: pointer;
}

.options .option:first-child {
    border-radius: 10px 0 0 0;
}

.options .option:last-child {
    border-radius: 0 10px 0 0;
}

.options .option h2 {
    font-weight: 800;
}


.options .option svg {
    display: none;
}

.options .option.active svg {
    display: block;
    width: 60px;
    height: 60px;
}


.options .option.active {
    background: #FFF;
    height: 250px;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #323745;    
    top: calc(100% - 5rem);
    transition: all .8s ease;
    will-change: top;
}


@media only screen and (max-width: 425px) {
    .page-title {
        font-size: 20px;
        padding: 50px;
    }

    .options .option {
        width: 150px;        
    }
}