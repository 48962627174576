.politics {
    color: #323745;
    background-repeat: no-repeat;
    background-image: url("../../resources/logo/LogotipoSQ_FondoCrema.png");
    background-position: center;
    max-width: 1344px;
    word-wrap: break-word;
    margin: auto;
}

h1.main-title {
    text-align: center;
    font-weight: 800;
}

h1.main-title,
.politics>article {
    margin: 4rem 0;
    cursor: default;
}

.calendar-items,
.appointment-list {
    padding-left: 18px
}

.notes {
    display: flex;
    flex-direction: column;
}

.note {
    font-size: 12px;
    font-weight: bolder;
}

.cancellation ul {
    list-style-type: none;
    padding: 0;
}


.calendar-items .calendar-item,
.cancellation ul li,
.appointment-list li {
    margin: 1em 0;
}

.appointment-nested-list li {
    margin: 0;
}

/* Media Query for Mobile Devices */
@media (max-width: 1465px) {
    .politics {
        font-size: 15px;
        padding: 3em;
        background-size: 300px;
    }

    h1.main-title {
        margin: 2rem 0;
        cursor: default;
    }
}