.divider {
    background: #E48873;
    height: 10px;
}

.footer-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
}

.main-footer {
    margin-top: 5em;
    width: 100%;
    background: #323745;
    color: #fff;
    z-index: 2;
}

.footer-container {
    padding: 4em 5em;
}

.footer-nav .title {
    font-size: 20px;
}

.footer-nav .nav {
    list-style-type: none;
    padding: 0;
}

.footer-nav .nav li {
    margin-bottom: 3px;
}

.footer-nav .nav li a {
    color: #9999 !important;
    text-decoration: none;
}

.footer-nav .social svg {
    margin-right: 10px;
    cursor: pointer;
}

.footer-nav .social a {
    color: #fff;
}


.download {
    display: flex;
    flex-direction: column;
    margin-top: 6em;
}


.download a {
    margin-top: 1em;
}

iframe {
    border: 0;
    border-radius: 5%;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 480px) {
    .footer-container {
        padding: 2em 2em;
    }

    iframe {
        width: 250px;
    }
}

@media screen and (min-width: 480px) and (max-width: 1160px) {
    .download {
        display: flex;
        flex-direction: column;
        margin: 2em 0;
    }

    iframe {
        width: auto;
    }
}