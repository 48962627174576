@font-face {
  font-family: 'Museo Sans';
  font-weight: 100;
  src: local('Museo Sans'), url(resources/fonts/MuseoSansCyrl-100.woff) format('woff'),
    url(./resources/fonts/MuseoSansCyrl-100Italic.woff) format('woff')
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 300;
  src: local('Museo Sans'), url(./resources/fonts/MuseoSansCyrl-300.woff) format('woff'),
    url(./resources/fonts/MuseoSansCyrl-300Italic.woff) format('woff')
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 500;
  src: local('Museo Sans'), url(./resources/fonts/MuseoSansCyrl-300.woff) format('woff'),
    url(./resources/fonts/MuseoSansCyrl-300Italic.woff) format('woff')
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 700;
  src: local('Museo Sans'), url(./resources/fonts/MuseoSansCyrl-700.woff) format('woff'),
    url(./resources/fonts/MuseoSansCyrl-700Italic.woff) format('woff'),
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 900;
  src: local('Museo Sans'),
    url(./resources/fonts/MuseoSansCyrl-900.woff) format('woff'),
    url(./resources/fonts/MuseoSansCyrl-900Italic.woff) format('woff'),
}



html,
* {
  box-sizing: border-box;
  font-family: Museo Sans;
}


body {
  margin: 0;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F6F6F4;
  color: #323745;
}


.rootLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  gap: 3rem;
}

.notFound h1 {
  font-size: 10rem;
  margin: 0;
}

.notFound p {
  font-size: 2rem;
  margin: 0;
}
