.body-responsive {
    display: none;
}

.m-bottom{
    margin-bottom: 1em !important;
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap-reverse;
    margin-top: 2px;
}

.about .banner-main {
    position: absolute;
    background-image: url("../../resources/more/background.png");
    height: 25em;
    width: 100%;
    z-index: 10;
}

.about .main-title {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    flex-wrap: wrap;
    text-align: center;
}

.main-title .main-body {
    margin-top: 9em;
    color: #323745;
    z-index: 20;
}

.main-body h1 {
    font-weight: 700;
}

.main-body p{
    margin: 0;
}

.main-body .body,
.body-responsive {
    margin-top: 13em;
    color: #323745;
    font-weight: 400;
    max-width: 50em;
    text-justify: inter-word !important;
    text-align: initial;
    background: center / contain no-repeat url("../../resources/logo/LogotipoSQ_FondoCrema.png");
}

.main-title .video-controls  {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 20;     
}

.video-controls svg{
    font-size: 35px;
    cursor: pointer;
}

.video-controls video{
    width: 346px;
    border: 10px solid #fff;
    border-radius: 2%;
}

.arrows-responsive{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 3em;    
}


.content {
    margin-top: 10em;    
}

.content .title {
    text-align: center;
    margin-bottom: 10em;
    color: #E48873;
}

.content .title h1 {
    font-size: 25px;
    text-align: center;

}

.content .title p {
    font-size: 40px;
    font-weight: 600;
    margin: 0;
}

.content .body .row {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 1em;
    gap: 1em
}


.team {
    cursor: pointer;
}

.team .image-team {
    width: 350px;
    height: 350px;
}


.description-team .name,
.description-team p {
    margin: .2em 0;
    color: #323745;
}

@media screen and (max-width: 425px) {
    .video-controls svg{
        display: none;
    }
    .body-responsive {        
        margin-top: 1em !important;
    }    
}

@media screen and (min-width: 425px) {
    .video-controls svg{
        display: block;
    }
    .arrows-responsive{
        display: none;
    }
}


@media screen and (max-width: 1235px) {
    .content .body .row {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}


@media only screen and (max-width: 1153px) {
    .body-responsive {
        display: inline;
        margin-top: 5em;
        padding: 2em;                
    }

.about .banner-main {
    top: 83px;
}

    .main-body .body {
        display: none;
    }

    .about .main-title {
        flex-direction: column;         
        align-items: center;       
    }
    
    .about .main-title video{
        height: auto;
        width: 300px;        
    }

    .main-title .main-body {
        margin-top: 5em;
        font-size: 15px;
    }

    .main-title video {
        margin-top: 2.2em;
    }
}