.navbar-module {
    color: #323745;
    background: #E8DFD8;
    font-weight: 500;
    font-size: 15px;
    height: 5rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.10), 0 5px 5px 0 rgba(0, 0, 0, 0.15);
}

.nav-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.navbar-nav {
    list-style-type: none;
    overflow: hidden;
    padding: 30px 0;
    margin: 0;
}


.nav-items {
    margin-left: 18em;
}

.navbar-nav .nav-item {
    display: inline-block;
    margin: 0 2em;
}

a.nav-link {
    display: inline-block;
    cursor: pointer;
    color: #323745;
    border: none;
    background: none;
    font-size: 16px;
    text-decoration: none;
    position: relative;
    width: 100%;
}

a.nav-link.active {
    font-weight: 600;
}

a.nav-link::after {
    position: absolute;
    content: '';
    height: 5px;
    bottom: -30px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 0;
    transition: .5s;
}

a.nav-link.active::after {
    position: absolute;
    content: '';
    height: 5px;
    bottom: -30px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    background: #323745;
    transition: .5s;
}

a.nav-link:hover::after {
    width: 100%;
    background: #323745;
}

a.nav-link:hover {
    font-weight: 600;
}

a.nav-link::before {
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.hamburguer-menu {
    visibility: hidden;
    position: absolute;
}

.hamburguer-menu.hamburger-active {
    visibility: visible;
    z-index: 30;
    width: 100%;
    background: #FFF;
    padding: 20px;
    transition: all .2s;
    top: 5.4em;
    margin: 0;
}

.hamburguer-menu>ul {
    list-style-type: none;
    font-weight: 600;
    font-size: 15px;
}

.hamburguer-menu>ul>a>* {
    padding: 1rem;
    cursor: pointer;
}

.hamburguer-items>a {
    text-decoration: none;
    color: #323745;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 1rem;
}

.hamburguer-items>a.hamburguer-item-active {
    font-weight: 700;
}

@media only screen and (max-width : 480px) {

    .nav-container {
        justify-content: space-between;
    }

    .nav-container>* {
        margin: 0 50px;
    }

    .nav-items {
        display: none;
    }

    .nav-brand>img {
        width: 70px;
    }

    .hamburguer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 20px;
    }

    .hamburguer>span {
        display: inline-block;
        width: 30px;
        height: 4px;
        background: #333744;
    }

}

@media only screen and (min-width: 481px) and (max-width: 900px) {
    .nav-container {
        justify-content: space-between;
    }

    .nav-container>* {
        margin: 0 50px;
    }

    .nav-items {
        display: none;
    }

    .nav-brand>img {
        width: 70px;
    }

    .hamburguer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 20px;
    }

    .hamburguer>span {
        display: inline-block;
        width: 30px;
        height: 4px;
        background: #333744;
    }
}

@media only screen and (min-width: 900px) {
    .hamburguer {
        display: none;
    }

    .hamburguer-menu {
        display: none;
    }
}