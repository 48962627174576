.home {
    margin-top: 3px;
}

.w-100{
    width: 100% !important;
}

.bg-pink {
    background: #DEC3C3 !important;
}

.bg-orange{
    background: #D0CAC4 !important;
}

.m-bottom{
    margin-bottom: 1em !important;
}

.reverse{
    flex-direction: row-reverse !important;
}

.d-none{
    display: none !important;
}

.title img {
    width: 100%;
}

.phrase {
    position: absolute;
    top: 0;
    margin-top: 20%;
    left: 50%;
}

.phrase>p {
    margin: 0;
    text-transform: uppercase;
    color: #323745;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
}

.phrase .button {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.info h1 {
    margin: 5em 5em;
    text-align: center;
    text-transform: uppercase;
    color: #323745;
    font-weight: 800;
    font-size: 20px;
    width: 100%;
}

.info .body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background: #E8DFD8;    

}

.info .body .text {
    max-width: 50%;
    font-size: 1.2vw;
    font-weight: 400;
}

.info .body img {
    width: 30%;
}

.info .body video {    
    width: 30%;
}

.info .reviews {
    background: #FFF;
    padding: 1%;
    width: 80%;
    border-radius: 8px;
    margin-bottom: 5%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.reviews .review-title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px !important;
}

.reviews .review-body {
    font-size: 15px !important;
}

.info .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 200px;
    width: auto;
}

.info .card {
    background: #FFF;
    min-width: 25%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card .card-title {
    background: #E8DFD8;
    padding: 3%;
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    border-radius: 10px 10px 0 0;
}

.card-title.app {
    background: #DEC3C3;
}

.card .card-body {
    padding: 2%;
    text-align: center;
}

.card-body img {
    width: 100%;
    height: 210px;
    border-radius: 5px;
}

.card .card-footer {
    display: flex;
    justify-content: center;
    padding: 10px 0 50px 0;
}



/* Media Query for Mobile Devices */
@media only screen and (max-width: 480px) {
    .phrase {
        margin-top: 30%;
    }

    .phrase .button {
        display: none;
    }


    .phrase>p {
        font-size: .9em;
    }

    .info>h1 {
        font-size: 20px;
        margin: 3em;
    }

    .info .body {
        flex-direction: column;
        padding: 1em 1em;
    }

    .info .body img,
    .info .body video {
        width: 100%;        
    }

    .info .body .text {
        max-width: 100% !important;
        font-size: 13px;                
        margin-bottom: 2rem;
    }

    .reviews {
        width: 90% !important;
    }

    .reviews .review-title {
        font-size: 15px !important;
    }

    .reviews .review-body {
        font-size: 12px !important;
        padding: .8rem;
    }

    .info .cards {
        justify-content: center;
        width: 90%;
        gap: 70px;
    }

}

/* Media Query for low resolution  Tablets, Ipads */
@media only screen and (min-width: 481px) and (max-width: 767px) {
    .phrase .button {
        display: none;
    }

    .phrase {
        margin-top: 25%;
    }

    .phrase>p {
        font-size: 1em;
    }

    .info .body img,
    .info .body video {
        width: 500px;
    }

    .info .body .text {
        margin-bottom: 2.5em;
        font-size: 20px;
        max-width: 100% !important;
    }

    .reviews {
        width: 90% !important;
    }

    .reviews .review-title {
        font-size: 15px !important;
    }

    .reviews .review-body {
        font-size: 12px !important;
        padding: 0.3rem .8rem;
    }

    .info .cards {
        justify-content: center;
        width: 90%;
        gap: 70px;
    }


}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .phrase>p {
        font-size: 1.2em;
    }

    .info .body img,
    .info .body video {
        width: 400px;
    }

    .info .body .text {
        margin-bottom: 2.5em;
        font-size: 20px;
        max-width: 100% !important;
    }


    .info .cards {
        justify-content: center;
        gap: 70px;
    }
}



@media only screen and (min-width: 1201px) {
    .phrase>p {
        font-size: 1.5em;
    }

    .info>h1 {
        font-size: 25px;
    }

    .info .body .text {
        font-size: 20px;
    }
}