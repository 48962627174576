

.plan {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 350px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    text-align: center;
    padding: 30px;
    background: #FFF;
}

.plan .plan-title p {
    font-weight: 600;
    font-size: 20px;
    margin: .8em 0 0 0;
}

.plan .plan-subtitle p {    
    margin: .2em 0 1em 0;
    font-size: .9em;
}
.plan .plan-subtitle span {    
    display: block;
    width: 100%;
    height: 30px;
}

.plan .plan-price{
    color: #999999b0;
    font-size: 30px;
    font-weight: 600;
}

.plan .plan-price p{
    margin: 0;
}

.plan ul {
    list-style-type: none;
    padding: 0;
}

.plan ul li {        
    display: flex;    
    vertical-align: bottom;
    align-items: center;
}

.plan ul li img{        
    margin: 20px;
}

.plan .plan-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: auto;
    height: 100%;    
}

.plan .plan-footer button {
    width: 80%;
}


.plan.active {
    border: 1px solid #E48873;
}

.plan:hover {
    cursor: pointer;
    border: 1px solid #E48873;
}
