.floating-option {
    visibility: hidden;
}

.floating-option.floating-option-active{
    visibility: visible !important;    
}

a.FloatingMenu_floating-option__kz0pw FloatingMenu_floating-option-active__9JYCh > button.MuiFab-instagramColor{
    background-color: black !important;
}