#carrousel {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 100vh;
}

#carrousel>.carrousel-title>.menu {
    align-items: center;
}

#carrousel .carrousel-title>.menu,
#carrousel .counter {
    display: flex;
    justify-content: space-evenly;
}


.carrousel-title {    
    text-align: center;
    padding: 5rem;
    background: no-repeat url("../../resources/more/background.png");
    background-size: cover;
}

.carrousel-title>img {
    width: 350px;
    height: 250px;
    border-radius: 5px;
}

.carrousel-title>* {
    margin-bottom: 5rem;
}

.arrow {
    cursor: pointer;
}


.counter {
    height: 20px;
}

.counter>span {
    display: inline-block;
    background-color: #323745;
    height: 5px;
    width: 50px;
}

#carrousel>.carrousel-summary {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 50%;
}


.summary-article>img {
    margin-right: 3rem;
    width: 200px;
}

@media only screen and (max-width: 800px) {
    #carrousel>.carrousel-title {
        width: 100%;
        font-size: 12px;
        padding: 1rem;
    }

    #carrousel>.carrousel-title>img {
        width: 300px;
    }

    #carrousel>.carrousel-summary {
        width: 100%;
        padding: 0 2em;
        margin-top: 2em;
    }

}


@media only screen and (min-width: 801px) and (max-width: 1150px) {
    #carrousel>.carrousel-title {
        width: 100%;
        font-size: 12px;
    }

    #carrousel>.carrousel-title>img {
        width: 350px;
    }

    #carrousel>.carrousel-summary {
        width: 100%;
        padding: 0 2em;
        margin-top: 2em;
    }

    .summary-article>img {
        margin-bottom: 3rem;
        width: 150px;
    }


}